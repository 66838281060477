import textCapitalize from '@/filters/text_formatters';
import { scrollToElement } from '@/utils/helpers';

const Banner = () => import('@/components/Banner.vue');
const PlanList = () => import('@/modules/service-plan/components/PlanList.vue');
const PlanListTable = () => import('@/modules/service-plan/components/PlanListTable.vue');
const MainFooter = () => import('@/components/MainFooter.vue');
const AskSection = () => import('../components/AskSection.vue');

export default {
  name: 'LandingView',

  components: {
    Banner,
    PlanList,
    PlanListTable,
    MainFooter,
    AskSection,
  },

  data() {
    return {
      bannerBackgroundUrl: `/img/${process.env.VUE_APP_SPORT_TYPE}/landing-header.png`,
      mfsLogoImg: '/img/logo/fs_cloud.svg',
      scrolled: false,
      selectedPlan: undefined,
      social: JSON.parse(process.env.VUE_APP_SOCIAL),
    };
  },

  computed: {
    hasServicePlan() {
      return this.$store.hasModule('servicePlan');
    },
    sportType() {
      return textCapitalize(process.env.VUE_APP_SPORT_TYPE);
    }
  },

  methods: {
    scrollToElement,
    redirectToRegister(plan) {
      if (plan) {
        this.$router.push({ name: 'account.register.plan', params: { plan: plan.level } });
      }
    },
  },

  watch: {
    selectedPlan: {
      handler: 'redirectToRegister',
      deep: true,
    },
  },
};
